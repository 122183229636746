// Import Tingle.js
import tingle from 'tingle.js';

// Create a global variable to keep track of the currently opened modal
let currentModal = null;

// var modal = new tingle.modal({
//     footer: true,
//     stickyFooter: false,
//     closeMethods: ['overlay', 'button', 'escape'],
//     closeLabel: "Close",
//     cssClass: ['modal-video'],
//     onOpen: function () {
//         console.log('modal open');
//     },
//     onClose: function () {
//         console.log('modal closed');
// 		this.destroy();
// 		console.log('close');
//     },
//     beforeClose: function () {
//         // here's goes some logic
//         // e.g. save content before closing the modal
//         return true; // close the modal
//         return false; // nothing happens
//     }
// });

// Function to transform a video URL into an embeddable format
function transformVideoUrl(videoUrl) {
    if (videoUrl) {
        if (videoUrl.includes('vimeo.com')) {
            // Extract the video ID from the Vimeo URL
            const vimeoVideoID = videoUrl.match(/\/(\d+)/)[1];
            // Construct the Vimeo embed URL
            return `https://player.vimeo.com/video/${vimeoVideoID}`;
        } else if (videoUrl.includes('youtube.com')) {
            // Extract the video ID from the YouTube URL
            const youtubeVideoID = videoUrl.match(/v=([^&]+)/)[1];
            // Construct the YouTube embed URL
            return `https://www.youtube.com/embed/${youtubeVideoID}`;
        } else if (videoUrl.includes('.mp4') || videoUrl.includes('.webm') || videoUrl.includes('.ogv')) {

        }
    }
    return videoUrl;
}

const videoControls = document.querySelectorAll('.video-controls');
videoControls.forEach(videoControl => {
    videoControl.addEventListener("click", (e) => {
        e.preventDefault();
        const videoUrl = videoControl.getAttribute('data-video-url');
        const transformedVideoUrl = transformVideoUrl(videoUrl); // Transform the URL

        // Check if there's an existing modal, destroy it before creating a new one
        if (currentModal) {
            currentModal.destroy();
            currentModal = null; // Clear the current modal reference
        }

		console.log(videoUrl);
		console.log(transformedVideoUrl);

        let modalContent;

        if (videoUrl.includes('vimeo.com') || videoUrl.includes('youtube.com')) {
            // Use iframe for Vimeo or YouTube URLs
            modalContent = `<iframe src="${transformedVideoUrl}" width="800" height="450" frameborder="0" allowfullscreen></iframe>`;
        } else {
            // Use HTML5 video element for other URLs
            const desiredWidth = 800;
            modalContent = `
                <video controls autoplay width="${desiredWidth}" height="auto">
                    <source src="${videoUrl}" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            `;
        }

        // Create a new tingle modal instance
        const modal = new tingle.modal({
			footer: false,
			stickyFooter: false,
			closeMethods: ['overlay', 'button', 'escape'],
			closeLabel: "Close",
			cssClass: ['custom-class-1', 'custom-class-2'],
			onOpen: () => {
				console.log('modal open');
			},
            onClose: () => {
                currentModal = null;
				console.log('close');
				modal.destroy();
            },
			beforeClose: function() {
				return true; // close the modal
				return false;
			}
        });

        // Set the modal content
        modal.setContent(modalContent);

        // Open the modal
        modal.open();

        currentModal = modal;
    });
});
