//
// JS
//
// import './modules/functions.js';
// import './modules/hero.js';
// import './modules/swiper.js';
// import './modules/navigation.js';
// import './modules/blocks.js';
import './modules/components.js';
// import './modules/forms.js';
// import './modules/instagram.js';
// import './modules/accessibility.js';
// import './modules/layout.js';

//
// Stylesheet
//
import "../sass/style.scss";

var $ = require("jquery");
require("jquery/package.json");

var jcf = require("jcf");

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
	// disable: 'mobile',
	once: true
});

import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

var pace = require('pace-js');
pace.start();

//NON TRIGGERED ELEMENTS//

console.log('main.js');

$('.arrow-down').click(function() {
    if ($(this).hasClass("active")) {
        // $('.menu-item.active').removeClass("active");
        $(this).removeClass("active");
        $(this).parent().parent().removeClass("active");
    } else {
        $('.menu-item.active').removeClass("active");
        $('.arrow-down.active').removeClass("active");
        $(this).addClass("active");
        $(this).parent().parent().addClass("active");
    }
    return false;
});

// Menu toggle classes
$('.menu-toggle').click(function() {
    $(this).toggleClass("is-active");
    $('html').toggleClass("lock-scroll");
    $('body').toggleClass("toggled");
});

$('.main-navigation').click(function() {
    var target = $( event.target );
    if ( target.is( '.main-navigation .menu' ) ) {
        return false; // Don't close menu when clicking the actual menu
    } else {
        $('.menu-toggle').toggleClass("is-active");
        $('html').toggleClass("lock-scroll");
        $('body').toggleClass("toggled"); // Close menu when clicking anywhere on the navigation div
        // return true;
    }
});

$('.dropdown-menu', this).each(function() {
    var minHeight = $(this).height()
    $('.dropdown-menu').css({'min-height':minHeight + 'px'});
});

// COVID COOKIES
// Banner Trigger if Not Closed
if (!localStorage.bannerClosed) {
    $('.cookie-pop-up').css('visibility', 'visible');
    $('.cookie-pop-up').css('opacity', '1');
} else {
    $('.cookie-pop-up').css('visibility', 'hidden');
    $('.cookie-pop-up').css('opacity', '0');
}
$('.close-covid').click(function() {
    $('.cookie-pop-up').css('visibility', 'hidden');
    $('.cookie-pop-up').css('opacity', '0');
    localStorage.bannerClosed = 'true';
    return false;
});
$('.close-covid').click(function() {
    $('.cookie-pop-up').css('visibility', 'hidden');
    $('.cookie-pop-up').css('opacity', '0');
    localStorage.bannerClosed = 'true';
});
if (navigator.userAgent.match(/Opera|OPR\//)) {
    $('.cookie-pop-up').css('visibility', 'hidden');
    $('.cookie-pop-up').css('opacity', '0');
}

// $(".close-covid").click(function() {
//     console.log('CLOSE POP UP');
// 	$(".cookie-pop-up").addClass("hide");
// });

// $('.nav-tab', this).each(function() {
//     $(this).removeClass("checked");
// });

// Tabs
'use strict';

function Tabs() {
    var bindAll = function() {
        var menuElements = document.querySelectorAll('[data-tab]');
            for(var i = 0; i < menuElements.length ; i++) {
            menuElements[i].addEventListener('click', change, false);
        }
    }
    var clear = function() {
        var menuElements = document.querySelectorAll('[data-tab]');
        for(var i = 0; i < menuElements.length ; i++) {
            menuElements[i].classList.remove('checked');
            var id = menuElements[i].getAttribute('data-tab');
            document.getElementById(id).classList.remove('checked');
        }
    }
    var change = function(e) {
        clear();
        e.target.classList.add('checked');
        var id = e.currentTarget.getAttribute('data-tab');
        document.getElementById(id).classList.add('checked');
    }
    bindAll();
}
var connectTabs = new Tabs();

// Javascript to enable link to tab
var hash = document.location.hash;
if (hash) {
    document.querySelectorAll('.tabs a[href="' + hash + '"]')[0].click();
}
$('.tabs a').click(function(e){ e.preventDefault()})
$('.popup-modal').click(function(e){ e.preventDefault()})

function resizeFacet_radios() {

}

// const button = document.querySelector('.button');
// button.addEventListener('click', () => {
//     const content = document.querySelector('.collapsible');
//     expandElement(content, 'collapsed');
// });



function expandElement(elem, collapseClass) {
    // debugger;
    elem.style.height = '';
    elem.style.transition = 'none';

    const startHeight = window.getComputedStyle(elem).height;

    // Remove the collapse class, and force a layout calculation to get the final height
    elem.classList.toggle(collapseClass);
    const height = window.getComputedStyle(elem).height;

    // Set the start height to begin the transition
    elem.style.height = startHeight;

    // wait until the next frame so that everything has time to update before starting the transition
    requestAnimationFrame(() => {
        elem.style.transition = '';

        requestAnimationFrame(() => {
            elem.style.height = height
        })
    })

    // Clear the saved height values after the transition
    elem.addEventListener('transitionend', () => {
        elem.style.height = '';
        elem.removeEventListener('transitionend', arguments.callee);
    });
}

// $(function() {
//     var mapOptions = {
//         zoom: 8,
//         center: new google.maps.LatLng(-34.397, 150.644)
//     };
//     var map = new google.maps.Map($("#map-canvas")[0], mapOptions);
//     // listen for the window resize event & trigger Google Maps to update too
//     $(window).resize(function() {
//         // (the 'map' here is the result of the created 'var map = ...' above)
//         google.maps.event.trigger(map, "resize");
//     });
// });

// CANVAS ICONS
function triggerNatural() {
    console.log('NATURAL');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-1').addClass('active');
    $('.canvas-text-1').addClass('active');
};
function triggerIntellectual() {
    console.log('Intellectual');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-4').addClass('active');
    $('.canvas-text-4').addClass('active');
};
function triggerPeople() {
    console.log('PEOPLE');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-2').addClass('active');
    $('.canvas-text-2').addClass('active');
};
function triggerManufactured() {
    console.log('Manufacture');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-5').addClass('active');
    $('.canvas-text-5').addClass('active');
};
function triggerHuman() {
    console.log('Human');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-3').addClass('active');
    $('.canvas-text-3').addClass('active');
};
function triggerFinancial() {
    console.log('Financial');
    $('.canvas-icon').removeClass("active");
    $('.canvas-text').removeClass("active");
    $('.canvas-icon-6').addClass('active');
    $('.canvas-text-6').addClass('active');
};

jQuery(document).ready(function($) {

    AOS.init({
        disable: function() {
            var maxWidth = 768;
            return window.innerWidth < maxWidth;
        }
    });

});
//NON TRIGGERED ELEMENTS END//

//TRIGGERED ELEMENTS//
function triggerJS(){
    jQuery(document).ready(function($){

        $('body').addClass('loaded');

    });
};
//TRIGGERED ELEMENTS END//

//SCROLLING
$(window).on("scroll", function() {

    // if ($(this).scrollTop() >= 25) {
    //     $('body').addClass("scrolled");
    // }
    // if ($(this).scrollTop() <= 25) {
    //     $('body').removeClass("scrolled");
    // }

});
//Scrolling end//

// ON UN LOAD //
$(window).on('beforeunload', function(){
    // $("body").removeClass("loaded");
    console.log('UNLOAD');
})
// ON LOAD END //

//ON LOAD//
$(window).on('load', function() {
    triggerJS();
    // jcf.replaceAll();

	// replace all form elements with modified default options
	// jcf.replace('button');
	// jcf.replace('wpcf7-submit');
	// jcf.replace('select');
	// // jcf.replace('textarea');
	jcf.replace('input[type="checkbox"]');
	// jcf.replace('input[type="file"]');
	// jcf.replace('input[type="number"]');
	// jcf.replace('input[type="radio"]');
	// jcf.replace('input[type="file"]');
	// jcf.replace('input[type="range"]');
	// jcf.replace('.jcf-scrollable');

    console.log('LOAD');

    // $(".tab").css({'height':cw+'px'});
    // $(".tab").css({'display', 'none'});
    // $(".tab").css("display", "none");
    $('.tab', this).each(function() {
        $(this).css("display", "none");
    });

})
//ON LOAD END//

//ON RESIZE//
$(window).resize(function() {

    triggerJS();
    console.log('RESIZE');

});
//ON RESIZE END//

// Plugin callback function
(function($) {

    $(document).on('facetwp-loaded', function() {
        $('.facetwp-template').animate({ opacity: 1 }, 1000);
        // jcf.replaceAll();
        AOS.init();
     });

     // Fade Content During Refresh
    $(document).on('facetwp-refresh', function() {
        $('.facetwp-template').animate({ opacity: 0 }, 1000);
        // jcf.replaceAll();
        AOS.init();
    });

})(jQuery);



function countUp() {
    const elements = document.querySelectorAll('.countup');

    elements.forEach(el => {
        let startValue = 0;
        const endValue = parseFloat(el.textContent.replace(/,/g, '')); // Get end value from the element
        const duration = 3500; // ms
        const isFloat = el.textContent.includes('.');

        // Intersection Observer setup
        const observerOptions = {
            root: null,
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    startCounting(el, startValue, endValue, duration, isFloat);
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);
        observer.observe(el);
    });

    function startCounting(el, startValue, endValue, duration, isFloat) {
        const startTime = performance.now();

        function updateCounter(currentTime) {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            let currentValue = progress * (endValue - startValue) + startValue;

            if (isFloat) {
                currentValue = currentValue.toFixed(1);
            } else {
                currentValue = Math.floor(currentValue).toLocaleString();
            }

            el.textContent = currentValue;

            if (progress < 1) {
                requestAnimationFrame(updateCounter);
            } else {
                if (isFloat) {
                    el.textContent = endValue.toFixed(1);
                } else {
                    el.textContent = endValue.toLocaleString();
                }
            }
        }
        requestAnimationFrame(updateCounter);
    }
}
document.addEventListener('DOMContentLoaded', countUp);

//
// Accordion
//
function accordion() {
    const accordions = document.querySelectorAll('.accordion');
    accordions.forEach(accordion => {
        const lists = accordion.querySelectorAll('li');

        // Open the first accordion item on load
        if (lists.length > 0) {
            const firstList = lists[0];
            const firstText = firstList.querySelector('.answer');
            const firstHeight = firstText.scrollHeight;

            firstList.classList.add('active');
            firstList.setAttribute('aria-expanded', 'true');
            firstText.style.maxHeight = firstHeight + 'px';
        }

        lists.forEach(list => {
            list.addEventListener('click', (e) => {
                // Check if the click target is a link inside the answer
                if (e.target.closest('.answer')) {
                    // If a link was clicked, do not toggle the accordion
                    e.preventDefault(); // Prevent default link behavior
                    return; // Exit the function
                }

                e.preventDefault(); // Prevent default action for the list item

                const text = list.querySelector('.answer');
                const height = text.scrollHeight;

                // Close other lists
                lists.forEach(otherList => {
                    if (otherList !== list) {
                        const otherText = otherList.querySelector('.answer');
                        if (otherText) {
                            otherText.style.maxHeight = '0px';
                        }
                        otherList.classList.remove('active');
                        otherList.setAttribute('aria-expanded', 'false');
                    }
                });

                // Toggle current list
                const isExpanded = list.getAttribute('aria-expanded') === 'true';
                list.setAttribute('aria-expanded', !isExpanded);
                if (isExpanded) {
                    text.style.maxHeight = '0px';
                    list.classList.remove('active');
                } else {
                    text.style.maxHeight = height + 'px';
                    list.classList.add('active');
                }
            });
        });
    });
}

// Initialize accordion on window load
window.addEventListener('load', accordion);




function teamPopup() {
	const markers = document.querySelectorAll('.team .marker');
	const closeButtons = document.querySelectorAll('.close');
	const popups = document.querySelectorAll('.pop-up');
	const overlay = document.querySelector('.team .overlay');
	markers.forEach(el => {
		el.addEventListener('click', () => {
			console.log(el);
            let sibling = el.nextElementSibling;
            // Loop through siblings until you find the one with class '.pop-up'
            while (sibling) {
                if (sibling.classList.contains('pop-up')) {
                    sibling.classList.add('active');
                    break;
                }
                sibling = sibling.nextElementSibling;
            }
		});
        // Remove 'active' class from .team .overlay on mouseover
        el.addEventListener('mouseover', () => {
			console.log('sog');
            if (overlay) {
                overlay.classList.remove('active');
            }
        });

        // Add 'active' class back to .team .overlay on mouseout
        el.addEventListener('mouseout', () => {
            if (overlay) {
                overlay.classList.add('active');
            }
        });
	})

    // Remove 'active' class from all .pop-up elements when .close is clicked
    closeButtons.forEach(button => {
        button.addEventListener('click', () => {
            const popups = document.querySelectorAll('.pop-up');
            popups.forEach(popup => {
                popup.classList.remove('active'); // Replace 'active' with the class you want to remove
            });
        });
    });
    // Remove 'active' class when clicking outside of the .pop-up-container but inside .pop-up
    popups.forEach(popup => {
        popup.addEventListener('click', (event) => {
            const container = popup.querySelector('.pop-up-container');
            if (!container.contains(event.target)) {
                popup.classList.remove('active'); // Replace 'active' with the class you want to remove
            }
        });
    });
}
window.addEventListener('DOMContentLoaded', teamPopup);


jQuery(document).ready(function($) {
	$('.marker-<?php echo $count; ?>').click(function() {
		$('.pop-up-<?php echo $count; ?>').toggleClass("active");
	});

	$('.close').click(function() {
		$('.pop-up').removeClass("active");
	});
});

document.addEventListener("DOMContentLoaded", function () {
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add("in-view");
			}
		});
	});

	const elements = document.querySelectorAll(".animated-element, .animated-element-alt");
	elements.forEach((el) => observer.observe(el));
});
